import { useState, useEffect, useMemo, useCallback } from 'react';

import { ID } from '@core/models';
import { useLocale } from '@core/utils/locale';
import useCuisines from '@core/api/useCuisines';
import { LocalesEnum } from '@core/enums/localeEnum';
import { CountryCodesEnum } from '@core/enums/flagsEnum';
import useSegmentations from '@core/api/useSegmentation';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import useRanges, { RangeDTO, RangesResponse, RangesTypesEnum } from '@core/api/useRanges';
import { CriterionEnum, CriterionParam, CriterionParamFilter } from '@core/models/Criterion';
import { OperatorAggregations, OperatorAggregationsModel } from '@core/models/OperatorAggregations';
import { OperatorFilter, useCountOperatorsQuery, useGetAggregationsQuery } from '@graphql/generated/graphql';
import {
    buildDropdownOptionTitle,
    parseRanges,
} from '@pages/OperatorTargetingCriteria/components/SegmentsCriteriaContent/GeneralCriteria/General.helpers';
import { filterAndParseSegmentsChannels } from '@pages/OperatorTargetingCriteria/helpers/Classification.helpers';

import useCriteriaDrilldown, { useCriteriaDrilldownReturnType } from './useCriteriaDrilldown';

export type useGetOperatorSummaryDataReturnType = {
    activeCountryCriterion: CriterionParam | undefined;
    aggregations: OperatorAggregationsModel;
    resultsCount: number;
    selectedOptionId: ID;
    setSelectedOptionId: (id: ID) => void;
    drillDown: useCriteriaDrilldownReturnType;
};

export const COUNTS_ID = 'counts';
export const PERCENTS_ID = 'percents';
export const DRILL_DOWN_LOCAL_STORAGE = 'DrillDownResults';

function useGetOperatorSummaryData(): useGetOperatorSummaryDataReturnType {
    const { locale } = useLocale();
    const [chainParams, setChainParams] = useState<CriterionParam[]>([]);
    const [selectedOptionId, setSelectedOptionId] = useState<ID>(COUNTS_ID);
    const [annualSalesRangeParams, setAnnualSalesRangeParams] = useState<CriterionParam[]>([]);

    const [aggregations, setAggregations] = useState<OperatorAggregationsModel>(
        new OperatorAggregations(undefined, undefined, locale),
    );

    const { doFetch: doFetchRanges } = useRanges();
    const drillDown = useCriteriaDrilldown(chainParams);

    const {
        searchFilters,
        getCriterionByFilterKey
    } = useAdvancedSearch();

    const activeCountryCriterion = useMemo(
        () => getCriterionByFilterKey(CriterionEnum.Location, CriterionParamFilter.Country)?.[0],
        [getCriterionByFilterKey],
    );

    const {
        data: segmentations,
        doFetch: doFetchSegments
    } = useSegmentations();

    const {
        data: cuisinesData,
        doFetch: doFetchCuisines
    } = useCuisines();

    const handleRangeResponse = useCallback(
        (ranges: NonNullable<RangesResponse['ranges']>, countryCode: CountryCodesEnum) => {
            Object.values(ranges).forEach((r) => {
                const type = r?.type as RangesTypesEnum;
                const criterionParamFilter = getCriterionParamFilterByRangeType(type);
                const rangeParams = parseRanges(
                    r?.elements as RangeDTO[],
                    type,
                    locale,
                    countryCode,
                    type !== RangesTypesEnum.AnnualSalesRangeType,
                )?.map((r) => mapRangeResponse(criterionParamFilter, r.id, r.title, r.min, r.max, locale));

                switch (type) {
                    case RangesTypesEnum.ChainSizeRangeType:
                        setChainParams(rangeParams as CriterionParam[]);
                        break;
                    case RangesTypesEnum.AnnualSalesRangeType:
                        setAnnualSalesRangeParams(rangeParams as CriterionParam[]);
                        break;
                }
            });
        },
        [locale],
    );

    useEffect(() => {
        if (activeCountryCriterion?.value) {
            const params = { country: activeCountryCriterion.value as string, locale };

            doFetchCuisines(params);
            doFetchSegments(params);
            doFetchRanges({ params }).then((res) => {
                if (res?.data?.ranges) {
                    handleRangeResponse(res.data.ranges, activeCountryCriterion.value as CountryCodesEnum);
                }
            });
        }
    }, [activeCountryCriterion?.value, doFetchSegments, doFetchCuisines, doFetchRanges, locale, handleRangeResponse]);

    const { data: countData } = useCountOperatorsQuery({
        variables: { filter: searchFilters as OperatorFilter },
        skip: !searchFilters,
    });

    const { data } = useGetAggregationsQuery({
        variables: { filter: searchFilters },
        skip: !searchFilters,
    });

    useEffect(() => {
        if (data?.aggregations) {
            setAggregations(
                new OperatorAggregations(
                    data.aggregations,
                    {
                        segmentations: filterAndParseSegmentsChannels(segmentations?.children),
                        cuisines: filterAndParseSegmentsChannels(cuisinesData?.children),
                        annualSalesRangeMappings: annualSalesRangeParams,
                    },
                    locale,
                    selectedOptionId === PERCENTS_ID,
                ),
            );
        }
    }, [data, locale, segmentations, annualSalesRangeParams, selectedOptionId, cuisinesData]);

    return {
        activeCountryCriterion,
        aggregations,
        resultsCount: countData?.count ?? 0,
        selectedOptionId,
        setSelectedOptionId,
        drillDown,
    };
}

export default useGetOperatorSummaryData;

function mapRangeResponse(
    criterionParamFilter: CriterionParamFilter | null,
    rangeId: ID,
    title: string,
    min: number | undefined,
    max: number | undefined,
    locale: LocalesEnum,
) {
    return criterionParamFilter
        ? {
            value: rangeId,
            name: getRangeResponseTitleName(criterionParamFilter, title, locale),
            filterKey: criterionParamFilter,
            additionalData: {
                min: min,
                max: max,
                condensedLabel: title,
            },
        }
        : null;
}

function getRangeResponseTitleName(criterionParamFilter: CriterionParamFilter, title: string, locale: LocalesEnum) {
    switch (criterionParamFilter) {
        case CriterionParamFilter.AnnualSalesRange:
            return title ?? '';
        default:
            return buildDropdownOptionTitle(title, criterionParamFilter, locale);
    }
}

function getCriterionParamFilterByRangeType(type: RangesTypesEnum) {
    switch (type) {
        case RangesTypesEnum.ChainSizeRangeType:
            return CriterionParamFilter.ChainSizeRange;
        case RangesTypesEnum.AnnualSalesRangeType:
            return CriterionParamFilter.AnnualSalesRange;
        default:
            return null;
    }
}
