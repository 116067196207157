import { useMemo, useState } from 'react';
import cn from 'classnames';

import { FCX, ID } from '@core/models';
import Tooltip from '@components/utility/Tooltip/Tooltip';

import './HorizontalStackedBarChart.styles.scss';

export type HorizontalStackedBarData = {
    id: ID;
    percentage: number;
    title: string;
    value: string;
    tooltipDescription?: string;
};

export type HorizontalStackedBarChartProps = {
    title: string;
    value: number;
    description?: string;
    percentageOfTotal: number;
    onBarClick?: (barId: ID, barTitle: string) => void;
    data: HorizontalStackedBarData[];
};

function calculateBackgroundSize(percentToShow: number) {
    return `${(100 / percentToShow) * 100}%`;
}

const MIN_PERCENTAGE = 1.5;

const HorizontalStackedBarChart: FCX<HorizontalStackedBarChartProps> = ({
    data,
    title,
    description,
    percentageOfTotal,
    onBarClick,
}) => {
    const [activeBarId, setActiveBarId] = useState<ID | null>(null);

    const parsedPercOfTotal = useMemo(() => Math.max(percentageOfTotal, MIN_PERCENTAGE), [percentageOfTotal]);

    return (
        <div className="HorizontalStackedBarChart">
            <div className="HorizontalStackedBarChart__title">{title}</div>
            <div className="HorizontalStackedBarChart__chart-container">
                <div
                    className="HorizontalStackedBarChart__bars-wrapper"
                    style={{
                        backgroundSize: calculateBackgroundSize(parsedPercOfTotal),
                        width: `${parsedPercOfTotal}%`,
                    }}
                >
                    {data.map((bar) => (
                        <Tooltip
                            key={bar.id}
                            content={
                                <div className="HorizontalStackedBarChart__tooltip">
                                    {bar.title}
                                    <span className="description">{bar.tooltipDescription}</span>
                                </div>
                            }
                            placement="top"
                        >
                            <div
                                className={cn('HorizontalStackedBarChart__bar', {
                                    dimmed: activeBarId && activeBarId !== bar.id,
                                })}
                                style={{ flexBasis: `${bar.percentage}%` }}
                                onMouseEnter={() => setActiveBarId(bar.id)}
                                onMouseLeave={() => setActiveBarId(null)}
                                onClick={() => onBarClick?.(bar.id, bar.title)}
                            />
                        </Tooltip>
                    ))}
                </div>
                {description}
            </div>
        </div>
    );
};

export default HorizontalStackedBarChart;
