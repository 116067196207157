import {
    AggregationCount,
    OperatorSearchAggregation as ApiOperatorAggregationsModel,
    Maybe,
} from '@graphql/generated/graphql';
import { RangesTypesEnum } from '@core/api/useRanges';
import { localizeNumber } from '@core/utils/currency';
import { DonutSlice } from '@components/utility/Charts/DonutChart/DonutChart';
import {
    HorizontalStackedBarChartProps,
    HorizontalStackedBarData,
} from '@components/utility/Charts/HorizontalStackedBarChart/HorizontalStackedBarChart';
import { getNumericPercentageOfTotal, getPercentageOfTotal } from '@core/helpers/helpers';
import { BarChartGroup } from '@components/utility/Charts/GroupedBarChart/GroupedBarChart';
import { ClassificationDTO } from '@pages/OperatorTargetingCriteria/helpers/Classification.helpers';
import { Dictionary, LocalesEnum, OperatorTargetingResultsPhrasesEnum } from '@core/enums/localeEnum';
import { ComparisonChartData } from '@components/utility/Charts/ComparisonBarChart/ComparisonBarChart';
import { VerticalProgressBarData } from '@components/utility/Charts/VerticalProgressBar/VerticalProgressBar';

import { CriterionParam } from './Criterion';

export type OperatorAggregationsModel = {
    chainsVsIndependents: [ComparisonChartData, ComparisonChartData] | null;
    segments?: BarChartGroup[] | null;
    annualSalesRange?: VerticalProgressBarData[] | null;
    confidenceLevel?: DonutSlice[] | null;
    cuisines?: CuisinesAggregation | null;
};

type CuisinesAggregation = Array<{ title: string; data: HorizontalStackedBarChartProps[] }>;

type ApiMappings = {
    annualSalesRangeMappings: CriterionParam[];
    segmentations: ClassificationDTO[];
    cuisines: ClassificationDTO[];
};

export class OperatorAggregations implements OperatorAggregationsModel {
    static defaultData: OperatorAggregationsModel = {
        chainsVsIndependents: null,
        segments: null,
        annualSalesRange: null,
        confidenceLevel: null,
        cuisines: null,
    };

    locale = LocalesEnum.En;
    showPercents = false;
    chainsVsIndependents = OperatorAggregations.defaultData.chainsVsIndependents;
    segments = OperatorAggregations.defaultData.segments;
    annualSalesRange = OperatorAggregations.defaultData.annualSalesRange;
    confidenceLevel = OperatorAggregations.defaultData.confidenceLevel;
    cuisines = OperatorAggregations.defaultData.cuisines;

    constructor(
        apiData?: ApiOperatorAggregationsModel,
        apiMappings?: ApiMappings,
        locale?: LocalesEnum,
        showPercents?: boolean,
    ) {
        if (locale) {
            this.locale = locale;
        }
        if (showPercents) {
            this.showPercents = showPercents;
        }
        if (apiData && apiMappings) {
            this.mapFromApi(apiData, apiMappings);
        }
    }

    private setData(model: OperatorAggregationsModel) {
        Object.assign(this, model);
    }

    private mapFromApi(apiData: ApiOperatorAggregationsModel, apiMappings: ApiMappings) {
        this.setData({
            chainsVsIndependents: this.parseChainsVsIndependents(apiData?.independent_vs_chain),
            segments: this.parseSegments(apiData?.segments, apiMappings?.segmentations),
            annualSalesRange: this.parseAnnualSalesRange(apiData?.ranges, apiMappings?.annualSalesRangeMappings),
            confidenceLevel: this.parseConfidenceLevel(apiData?.confidence_level),
            cuisines: this.parseCuisines(apiData?.cuisine, apiMappings?.cuisines),
        });
    }

    private parseCuisines(data: ApiOperatorAggregationsModel['cuisine'], cuisines: ClassificationDTO[]) {
        if (!data || !data.length) {
            return null;
        }

        function handleLowPercentageString(percentage: string) {
            return percentage === '0.0%' ? '< 0.1%' : percentage;
        }

        function mapSubCuisinesData(subCuisine: ClassificationDTO) {
            return (
                subCuisine?.children?.map((secondaryCuisine) => {
                    return {
                        id: secondaryCuisine?.external_id ?? '',
                        title: secondaryCuisine?.description ?? '',
                        value:
                            data
                                ?.filter((item) => item?.key?.includes(`${secondaryCuisine?.external_id}`))
                                ?.reduce((acc, item) => acc + (item?.count ?? 0), 0)
                                ?.toString() ?? '0',
                        percentage: 0,
                    };
                }) ?? []
            );
        }

        function mapSubCuisinesToHorizontalStackedBarChartProps(
            cuisine: ClassificationDTO,
        ): HorizontalStackedBarChartProps[] {
            return (
                cuisine?.children?.map((subCuisine) => {
                    return {
                        title: subCuisine?.description ?? '',
                        value:
                            data?.reduce((acc, item) => {
                                if (item?.key?.includes(`${subCuisine?.external_id}.`)) {
                                    return acc + (item?.count ?? 0);
                                }

                                return acc;
                            }, 0) ?? 0,
                        percentageOfTotal: 0,
                        data: mapSubCuisinesData(subCuisine),
                    };
                }) ?? []
            );
        }

        const parseSubCuisineData = (subCuisineData: HorizontalStackedBarData[], value: number) => {
            return subCuisineData
                .map((cuisineBar) => {
                    return {
                        ...cuisineBar,
                        percentage: getNumericPercentageOfTotal(value, parseInt(cuisineBar.value)),
                        tooltipDescription: this.showPercents
                            ? handleLowPercentageString(getPercentageOfTotal(value, parseInt(cuisineBar.value)))
                            : localizeNumber(parseInt(cuisineBar.value), this.locale),
                    };
                })
                .filter((cuisineBar) => cuisineBar.percentage > 0)
                .sort((a, b) => parseInt(b.value) - parseInt(a.value));
        };

        const cuisinesData: CuisinesAggregation = cuisines
            .map((cuisine) => {
                const subCuisines = mapSubCuisinesToHorizontalStackedBarChartProps(cuisine);
                const subCuisinesTotal = subCuisines.reduce((acc, item) => acc + item.value, 0);
                const subCuisinesMax = subCuisines.reduce((acc, item) => Math.max(acc, item.value), 0);

                const parsedSubCuisines: HorizontalStackedBarChartProps[] = subCuisines
                    .map((subCuisine) => {
                        return {
                            ...subCuisine,
                            percentageOfTotal: getNumericPercentageOfTotal(subCuisinesMax, subCuisine.value),
                            description: this.showPercents
                                ? handleLowPercentageString(getPercentageOfTotal(subCuisinesTotal, subCuisine.value))
                                : localizeNumber(subCuisine.value, this.locale),
                            data: parseSubCuisineData(subCuisine.data, subCuisine.value),
                        };
                    })
                    .filter((item) => item.percentageOfTotal > 0)
                    .sort((a, b) => {
                        return b.value - a.value;
                    });

                return {
                    title: cuisine?.description ?? '',
                    data: parsedSubCuisines,
                };
            })
            .filter((item) => item.data.length > 0);

        return cuisinesData;
    }

    private parseConfidenceLevel(data: ApiOperatorAggregationsModel['confidence_level']): DonutSlice[] | null {
        if (!data || !data.length) {
            return null;
        }

        const total = data.reduce((sum, item) => sum + (item?.count ?? 0), 0);

        return data.map((item) => {
            const value = item?.count ?? 0;
            const percent = total ? (value / total) * 100 : 0;

            return {
                id: item?.key ?? '',
                value: value,
                percent: percent,
            };
        });
    }

    private parseChainsVsIndependents(data: ApiOperatorAggregationsModel['independent_vs_chain']) {
        if (!data || !data.length) {
            return null;
        }

        const apiKeyToTitle = {
            chain: OperatorTargetingResultsPhrasesEnum.ChainUnits,
            independent: OperatorTargetingResultsPhrasesEnum.IndependentUnits,
        };

        const chartSum = data.reduce((acc, item) => acc + item?.count, 0);

        return data.map((item) => {
            const phraseEnum = apiKeyToTitle?.[item?.key as keyof typeof apiKeyToTitle];
            const title = Dictionary?.[phraseEnum]?.[this.locale] ?? '';

            return {
                id: item?.key ?? '',
                title,
                formattedValue: localizeNumber(item?.count, this.locale),
                percentValue: getPercentageOfTotal(chartSum, item?.count),
            };
        }) as [ComparisonChartData, ComparisonChartData];
    }

    private parseAnnualSalesRange(
        data: ApiOperatorAggregationsModel['ranges'],
        annualSalesRangeMappings: CriterionParam[],
    ): VerticalProgressBarData[] | null {
        if (!data?.length || !annualSalesRangeMappings.length) {
            return null;
        }

        const countMap: Record<string, number> = {};

        function flattenAndMapAggregation(aggregationData: ApiOperatorAggregationsModel['ranges']) {
            aggregationData
                ?.filter((data) => data?.description == RangesTypesEnum.AnnualSalesRangeType)
                ?.forEach((aggregation) => {
                    aggregation?.elements?.forEach((element) => {
                        if (element?.key && element?.count) {
                            countMap[element.key] = element?.count;
                        }
                    });
                });
        }
        flattenAndMapAggregation(data);

        const dataSum = Object.values(countMap).reduce((acc, val) => acc + val, 0);
        const dataMax = Math.max(...Object.values(countMap));

        return (
            annualSalesRangeMappings?.map((rangeDetails): VerticalProgressBarData => {
                const count = countMap[rangeDetails?.value ?? ''] ?? 0;
                return {
                    id: rangeDetails?.value ?? '',
                    title: rangeDetails?.name ?? '',
                    value: this.showPercents
                        ? getPercentageOfTotal(dataSum, count)
                        : localizeNumber(count, this.locale),
                    progress: (count / dataMax) * 100,
                };
            }) ?? []
        );
    }

    private parseSegments(
        data: ApiOperatorAggregationsModel['segments'],
        segmentations: ClassificationDTO[],
    ): BarChartGroup[] | null {
        if (!data?.length || !segmentations.length) {
            return null;
        }

        function createSegmentCodeCountMap(
            aggregationData: ApiOperatorAggregationsModel['segments'],
            countMap?: Record<string, number>,
        ) {
            const currentCountMap = countMap ?? {};

            aggregationData?.forEach((aggregation) => {
                if (aggregation?.key && aggregation?.count) {
                    currentCountMap[aggregation.key] = aggregation?.count;
                }

                if (aggregation?.children) {
                    createSegmentCodeCountMap(aggregation.children, currentCountMap);
                }
            });

            return currentCountMap;
        }

        function getAggregationCountChildrenMaxCount(aggregation: Maybe<AggregationCount>[]) {
            return aggregation?.reduce((acc, item) => {
                const childrenMax = item?.children?.reduce((acc, item) => Math.max(acc, item?.count ?? 0), 0);
                return Math.max(acc, childrenMax ?? 0);
            }, 0);
        }

        function getAggregationCountItemsSum(aggregation: Maybe<AggregationCount>[]) {
            return aggregation?.reduce((acc, item) => acc + (item?.count ?? 0), 0);
        }

        function mapSegmentationToBarChartGroup(
            segmentationChannel: ClassificationDTO[] | undefined,
            aggregation: Maybe<AggregationCount>[],
            showPercents: boolean,
            locale: LocalesEnum,
        ): BarChartGroup[] {
            return (
                segmentationChannel?.map((subChannel): BarChartGroup => {
                    const count = countMap[subChannel?.external_id ?? ''] ?? 0;
                    const subchannelAgg = aggregation?.find((agg) => subChannel?.external_id?.includes(agg?.key ?? ''));

                    const segments: BarChartGroup[] =
                        subChannel?.children?.map((item) => {
                            const segmentCount = countMap[item?.external_id ?? ''] ?? 0;
                            const highestSegmentCount = getAggregationCountChildrenMaxCount(
                                subchannelAgg?.children ?? [],
                            );

                            return {
                                id: item?.external_id ?? '',
                                title: item?.description ?? '',
                                count: countMap[item?.external_id ?? ''] ?? 0,
                                tooltipText: showPercents
                                    ? getPercentageOfTotal(subchannelAgg?.count, segmentCount)
                                    : localizeNumber(segmentCount, locale),
                                value: segmentCount,
                                percentage: getPercentageOfTotal(highestSegmentCount, segmentCount),
                                items: [],
                            };
                        }) ?? [];

                    return {
                        id: subChannel?.external_id ?? '',
                        title: subChannel?.description ?? '',
                        count,
                        tooltipText: showPercents
                            ? getPercentageOfTotal(getAggregationCountItemsSum(aggregation), count)
                            : localizeNumber(count, locale),
                        percentage: getPercentageOfTotal(getAggregationCountChildrenMaxCount(aggregation), count),
                        items: segments,
                    };
                }) ?? []
            );
        }

        const countMap = createSegmentCodeCountMap(data);

        return segmentations.map((segmentChannel): BarChartGroup => {
            if (!segmentChannel?.external_id) {
                return { id: '', title: '', items: [] };
            }

            return {
                id: segmentChannel?.external_id,
                count: countMap?.[segmentChannel?.external_id],
                title: segmentChannel?.description ?? '',
                items: mapSegmentationToBarChartGroup(
                    segmentChannel?.children ?? [],
                    data,
                    this.showPercents,
                    this.locale,
                ),
            };
        });
    }
}
