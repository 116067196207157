import React from 'react';
import './Card.styles.scss';
import Text, { TextVariant } from '@components/ui/Typography/Text';

type CardProps = {
    name: string;
    description: string;
    price: string;
};

const Card: React.FC<CardProps> = ({
    name,
    description,
    price
}) => {
    return (
        <div className="Card">
            <Text testId="textCardName" variant={TextVariant.B1}>
                {name && <span className="Card__name">{name}</span>}
            </Text>
            <Text testId="textCardDescription" variant={TextVariant.B2} className="Card__description">
                {description}
            </Text>
            <Text testId="textCardPrice" variant={TextVariant.B1} className="Card__price">
                {price}
            </Text>
        </div>
    );
};

export default Card;
