import { useMemo } from 'react';

import { FCX } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { FilterTypeEnum } from '@core/enums/localeEnum';
import { SegmentCodesEnum } from '@core/models/Segments';
import { formatNumberByLocale } from '@core/utils/string';
import Collapse from '@components/utility/Collapse/Collapse';
import { WidgetType } from '@components/utility/Widget/Widget';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import Heading, { HeadingVariant } from '@components/ui/Typography/Heading';
import GroupedBarChart from '@components/utility/Charts/GroupedBarChart/GroupedBarChart';
import { useOperatorTargetingResultsContext } from '@pages/OperatorTargetingResults/OperatorTargetingResults.context';
import GroupedHorizontalStackedBarChart from '@components/utility/Charts/HorizontalStackedBarChart/GroupedHorizontalStackedBarChart';

const RestaurantsAndBarsCharts: FCX = () => {
    const isCuisinesSummaryEnabled = useFeatureFlag(FeatureFlags.CuisinesSummary);

    const {
        t,
        locale
    } = useLocale();

    const {
        summaryData: {
            aggregations,
            drillDown: {
                onDrillDownSegments,
                onDrillDownCuisine
            },
        },
    } = useOperatorTargetingResultsContext();

    const restaurantsAndBarsAggregations = useMemo(
        () => aggregations?.segments?.find((segment) => segment.id === SegmentCodesEnum.RestaurantsAndBars),
        [aggregations],
    );

    if (!restaurantsAndBarsAggregations?.count || restaurantsAndBarsAggregations?.count === 0) {
        return null;
    }

    return (
        <Collapse
            title={
                <Heading variant={HeadingVariant.H3}>
                    {t(FilterTypeEnum.RestaurantsAndBars)} (
                    {formatNumberByLocale(restaurantsAndBarsAggregations?.count ?? 0, locale)})
                </Heading>
            }
            startCollapsed={false}
            widgetProps={{
                type: WidgetType.Section,
                className: 'section-small',
            }}
        >
            <div className="OperatorResultsSummary__charts">
                {restaurantsAndBarsAggregations && (
                    <div className="OperatorResultsSummary__segments">
                        <Heading variant={HeadingVariant.H4}>{t(FilterTypeEnum.Segments)}</Heading>
                        <GroupedBarChart
                            groups={restaurantsAndBarsAggregations.items ?? []}
                            onBarClick={onDrillDownSegments}
                        />
                    </div>
                )}
            </div>

            {isCuisinesSummaryEnabled && (
                <div className="OperatorResultsSummary__charts">
                    {aggregations?.cuisines?.map((cuisineSummary) => (
                        <div key={cuisineSummary.title} className="OperatorResultsSummary__cuisines">
                            <Heading variant={HeadingVariant.H4}>{cuisineSummary.title}</Heading>
                            <GroupedHorizontalStackedBarChart
                                groups={cuisineSummary.data}
                                onBarClick={onDrillDownCuisine}
                            />
                        </div>
                    ))}
                </div>
            )}
        </Collapse>
    );
};

export default RestaurantsAndBarsCharts;
